<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
     <a-alert
      v-if="successMessage"
      type="success"
      :message="successMessage"
      banner
      closable
    />
    <div class="row">
      <div class="col myform" :rules='rules'>
        <filter-distributor
        v-if="distri !== 238 && distri !== 239 && distri !== 240 && distri !== 254 && distri !== 982"
        :disabled="(isDistributor) && (distri !== 238 && distri !== 239 && distri !== 240 && distri !== 254 && distri !== 982)"
        class="mr-2"
        v-model:value="distributors"
        :mode="null"
        :allow-clear="false"
        show-search
        required
        name="distributors"
        >
        </filter-distributor>

        <a-select
          ref="select"
          placeholder="Pilih Distributor"
          class="mr-2"
          v-model:value="distributors"
          style="width: 300px"
          v-if="distri === 238 || distri === 239 || distri === 240 || distri === 254 || distri === 982"
        >
          <a-select-option :value="238">238 - SEMEN INDONESIA DISTRIBUTOR(JATIM), PT</a-select-option>
          <a-select-option :value="239">239 - SEMEN INDONESIA DISTRIBUTOR, PT (JABARDKI)</a-select-option>
          <a-select-option :value="240">240 - SEMEN INDONESIA DISTRIBUTOR (BALI), PT</a-select-option>
          <a-select-option :value="254">254 - SEMEN INDONESIA DISTRIBUTOR (JATENG-DIY), PT</a-select-option>
          <a-select-option :value="982">982 - SEMEN INDONESIA DISTRIBUTOR, PT</a-select-option>
        </a-select>

        <a-date-picker
          style="width: 300px;"
          class=" mt-2 mr-2"
          v-model:value="start_date"
          :disabled-date="disabledStartDate"
          :format="formatDated"
          placeholder="Tanggal"
        />
        <filter-jenis
          class="mt-2 mr-2"
          placeholder="Pilih Jenis API"
          v-model:value="jenis"
          :mode="null"
          :allow-clear="false"
          required
          name= "jenis"
        ></filter-jenis>
        <a-button class="m-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <a-button
          class="m-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download JSON To Xls"
          @click="showDownload"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
           <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <br />
    <h5>Result API</h5>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body" style="background: #e3e3e3;">
            <pre id="resultApi"></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  reactive,
  inject,
  onMounted,
  computed,
  nextTick,
  toRefs,
  onBeforeMount,
  provide,
} from 'vue'
import Loading from '@/components/Loading'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterJenis from '@/components/filter/FilterJenisApi'
import useUserInfo from '@/composables/useUserInfo'
import { useDisabledDate } from '@/composables/DisabledDate'
import moment from 'moment'
import store from '@/store'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    FilterDistributor,
    FilterJenis,
  },
  setup() {
    const modal1Visible = ref(false)
     const modal2Visible = ref(false)
     const modal3visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const data = ref([])
    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')
    const modalRef = ref('')
    const errorMessage = ref(null)
    const distri = ref(store.state.user.vendor_id)
    const distributors = ref([])
    const distributor = ref([])
    const jenis = ref([])

    const { start_date, disabledStartDate, startDate } = useDisabledDate()
    const formatDated = 'YYYY-MM-DD'
    start_date.value = moment(new Date()).add(-1, 'days')

    const { isDistributor, vendor_id } = useUserInfo()
    if (isDistributor.value) {
      distributors.value.push(vendor_id.value)
    }

    const params = ref({})
    const response = ref()
    const fetchData = () => {
      const _params = {
        vendor_id: distributors.value,
        type: jenis.value,
        date: startDate.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/vendor-rest-apies/request', {
          params: _params,
        })
        .then(response => {
          const { items, _meta, status, statusText } = response.data
          var resultApi = document.getElementById('resultApi')
          resultApi.innerHTML = JSON.stringify(response.data, undefined, 2)
          successMessage.value = `${status}`
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            var resultError = document.getElementById('resultApi')
            resultError.innerHTML = ''
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('api/vendor-rest-apies/request', {
          params: { ...params.value, _exportall: 'xls' },
          responseType: 'blob',
          headers: {},
        })
       .then(response => {
          if (response === null) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `api-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const state = reactive({
      loading: false,
      isFetching: false,
      isDownloading: false,
    })
    onMounted(() => {

      // fetchData()
    })

    const search = visible => {
      if (visible) {
         if (distributors.value.length === 0) {
         message.warning('Pilih Distributor !')
        }else if(jenis.value.length === 0){
          message.warning('Pilih Jenis API !!')
        }
        else{
          fetchData()
        }
      }
      modal3Visible.value = visible
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }

    const handleCancel = () => {
      visible.value = false
    }

    const title = computed(() => {
      return 'Level Stock'
    })

    const showDownload = visible => {
      if (visible) {
         if (jenis.value.length === 0) {
         message.warning('Pilih jenis API!')
        }
        else{
          fetchXlsx()
        }
      }
      modal2Visible.value = visible
    }


    const rules = {
      distributors: {
        required: true,
        message: 'Pilih Distributors!',
      },
      jenis: {
        required: true,
        message: 'Pilih Jenis API',
      },
    }

    return {
      modal1Visible,
      modal2Visible,
      modal3visible,
      setModal1Visible,
      handleOk,
      handleCancel,
      q,
      searchText,
      searchInput,
      data,
      current1,
      jenis,
      distributors,
      state,
      fetchXlsx,
      fetchData,
      ...toRefs(state),
      start_date,
      search,
      title,
      response,
      disabledStartDate,
      formatDated,
      moment,
      errorMessage,
      rules,
      showDownload,
      modalRef,
      isDistributor,
      distri,
      distributor,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
