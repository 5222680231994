/* eslint-disable */

<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Jenis API"
    allow-clear
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="blur"
    @select="select"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.value" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'
export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      type: String,
      default: 'multiple',
    },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)
    const loading = ref(false)

    const fetchData = (q = null) => {
      loading.value = true
      apiClient
        .get('/api/filter/synchron-type', {
          params: { q },
        })
        .then(response => {
          data.value = response.data
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = debounce(value => {
      loading.value = true
      findText.value = value
      fetchData(value)
    }, 300)

    const highlight = value => {
      if (value === undefined || value === null) return value
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }

    const select = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
    }

    const blur = value => {
      console.log({ blur: '', value })
    }

    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      loading,
      deselect,
      blur,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
