<template>
  <a-skeleton active loading />
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style></style>
